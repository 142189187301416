<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col">
          <ul>
            <li><strong>E-mail</strong></li>
            <li><a class="d-flex" href="mailto:contact@liefslune.nl">
              <img src="@/assets/img/email.png" alt="Email" /> 
              <p>contact@liefslune.nl</p></a></li>
          </ul>
        </div>
        <div class="col socials">
        <ul>
            <li><strong>Socials</strong></li>
            <li>          
              <a class="d-flex" href="https://www.instagram.com/liefslune/" target="_blank">
                <img src="@/assets/img/Instagram-icon.png" alt="Instagram" /> 
                <p>
                  liefslune
                </p>
              </a>
            </li>
            <li>          
              <a class="d-flex" href="https://www.facebook.com/liefslune/" target="_blank">
                <img src="@/assets/img/Facebook-icon.png" alt="Facebook" /> 
                <p>
                  liefslune
                </p>
              </a>
            </li>
          </ul>
        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
      </div>
    </div>
  </footer>
</template>
