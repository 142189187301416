<template>
  <div class="container">
    <div class="row">
      <div class="col col1"></div>

      <div class="col-11">
        <Carousel :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="slide in slides" :key="slide">
            <div class="carousel__item"><img :src="slide" /></div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
        <button @click="$router.push('Fotos')" class="imagebutton">Meer foto's</button>
      </div>
      <div class="col col3"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Basic",
  components: {
    Carousel,
    Slide,
    Navigation,
  },

  data() {
    return {
      slides: [
        //   voeg hier de images toe voor in de carousel
        require("../assets/img/wikkelsetjes/image1.jpg"),
        require("../assets/img/wikkelsetjes/image7.jpg"),
        require("../assets/img/wikkelsetjes/image6.jpg"),
        require("../assets/img/wikkelsetjes/image2.jpg"),
        require("../assets/img/wikkelsetjes/image3.jpg"),
        require("../assets/img/wikkelsetjes/image4.jpg"),
        require("../assets/img/wikkelsetjes/image5.jpg"),
        require("../assets/img/wikkelsetjes/image8.jpg"),
      ],
    };
  },
  setup() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
      },

      breakpoints: {
        700: {
          itemsToShow: 1.9,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
