import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Over-ons",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/Fotos",
    name: "Images",
    component: () => import("../views/Images.vue"),
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/Doneren",
    name: "Donate",
    component: () => import("../views/Donate.vue"),
  },
];



const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    } else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
 },
  routes,
});

export default router;
