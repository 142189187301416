<template>
  <div class="container hospitals">
    <h2 class="text-center">Deelnemende ziekenhuizen</h2>
    <div class="row justify-content-center">
      <div class="col-10" style="display: inline-block; overflow: hidden">
        <!-- SEE READ ME FOR ADDING LOCATION GOOGLE MAPS-->
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1idburls7-1zwg5Yas_MfsYTp7SZWNp8f&ehbc=2E312F"
          width="100%"
          height="600px"
          style="position: relative; top: -50px; border: none"
        ></iframe>
      </div>
    </div>
  </div>
</template>