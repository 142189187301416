<template>
  <main>
    <section class="homeHeader">
      <home-header></home-header>
    </section>
    <section class="imageSlider" data-aos="fade-up" data-aos-duration="1500">
      <h2>Foto's</h2>
      <image-carousel></image-carousel>
    </section>
    <section class="maps" data-aos="fade-up" data-aos-duration="1500">
      <participating-hospitals></participating-hospitals>
    </section>

    <div class="waves overflow-hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 1918.999 147.568"
      >
        <path
          id="Intersection_17"
          data-name="Intersection 17"
          d="M40.951-3974v-87.3c28.48-16.253,69.47-32.9,117.927-32.9,94.683,0,143.43,63.552,217.854,63.552s148.342-68.879,206.915-63.552,99.738,63.552,161.8,63.552,71.029-48.5,141.824-44.331,114.686,68.7,190.618,65.778S1182.14-4111.744,1244-4120.843s62.2,70.478,130.934,75.248,60.771-75.248,144.608-75.248,86.952,90.2,172.517,90.2,93.423-90.2,184.273-90.2c36.237,0,63.7,12.092,83.618,27V-3974Z"
          transform="translate(-40.951 4121.567)"
          fill="#140c4e"
        />
      </svg>
    </div>
    <section class="reviewsContainer">
      <parent-reviews></parent-reviews>
    </section>
    <div class="waves overflow-hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 1918 147.568"
      >
        <path
          id="Intersection_18"
          data-name="Intersection 18"
          d="M1244-3958.724c-61.859-9.1-90.181-108.72-166.113-111.644s-119.822,61.614-190.618,65.779-79.765-44.332-141.824-44.332-103.226,58.226-161.8,63.553-132.491-63.553-206.915-63.553-123.17,63.553-217.853,63.553c-48.174,0-88.969-16.449-117.428-32.612v-87.588h1918v120.212c-19.881,14.741-47.182,26.632-83.119,26.632-90.85,0-98.707-90.2-184.274-90.2s-88.679,90.2-172.516,90.2-75.87-80.017-144.608-75.248c-65.029,4.512-68.833,75.977-121.432,75.972A65.357,65.357,0,0,1,1244-3958.724Z"
          transform="translate(-41.451 4105.568)"
          fill="#140c4e"
        />
      </svg>
    </div>

    <section class="donate">
      <div class="container">
        <div class="row" data-aos="fade-up" data-aos-duration="1500">
          <div class="col-10 col-sm">
            <div class="row justify-content-center">
              <h2>Hoe kan ik helpen?</h2>
              <p>
                Als je graag iets wil bijdragen kan dat doormiddel van een gelddonatie of een stofdonatie.
                <br /><br />
                Met een gelddonatie kunnen wij materialen aanschaffen die wij niet, of onvoldoende als donaties ontvangen. Denk hierbij aan:
                <ul>
                  <li>
                    <div>
                      <img src="@/assets/img/vink-blue.png" class="img-fluid" /> Fleece
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="@/assets/img/vink-blue.png" class="img-fluid" /> Specifieke stoffen voor de buitenkant
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="@/assets/img/vink-blue.png" class="img-fluid" /> Linten
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="@/assets/img/vink-blue.png" class="img-fluid" /> Naaigaren
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="@/assets/img/vink-blue.png" class="img-fluid" /> Verpakkingsmateriaal (zakjes, plakband, maatstickers)
                    </div>
                  </li>
                  <li>
                    <div>
                      <img src="@/assets/img/vink-blue.png" class="img-fluid" /> Verzendkosten voor verzending naar ziekenhuizen of ouders
                    </div>
                  </li>
                </ul>
              </p>
              <button @click="$router.push('/Doneren')" :class="{ 'color-nav': $route.path == '/fotos' }">Over doneren</button>
            </div>
          </div>
          <div class="col-10 col-sm">
            <img src="@/assets/img/help-illustratie@2x.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HomeHeader from "@/components/HomeHeader.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import ParticipatingHospitals from "@/components/ParticipatingHospitals.vue";
import ParentReviews from "@/components/ParentReviews.vue";

export default {
  name: "App",
  components: {
    HomeHeader,
    ImageCarousel,
    ParticipatingHospitals,
    ParentReviews,
  },
};


</script>
