<template>
  <div class="container" data-aos="fade-up" data-aos-duration="1000">
    <div class="row">
      <div class="col-10 col-md"><img :src="illustration" /></div>
      <div class="col-10 col-md">
          <h2>Een mooi afscheid en een waardevolle herinnering</h2>
          <p>
            Wij maken kosteloos wikkelsetjes voor stilgeboren kindjes en hun ouders. 
            Het setje bestaat uit een wikkeldoekje voor het kindje en een doekje voor de ouders om te bewaren als herinnering. 
            De wikkelsetjes leveren wij aan ziekenhuizen of aan de ouders zelf.
          </p>
          <button @click="$router.push('Over-ons')">Over ons</button>
        </div>
    </div>
  </div>
  <div class="waves">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1919 147.568">
  <path id="Intersection_16" data-name="Intersection 16" d="M59.948-3953.432v-97.177c26.559-12.49,60.384-23.023,98.93-23.023,94.683,0,143.43,63.553,217.853,63.553s148.343-68.88,206.915-63.553,99.738,63.553,161.8,63.553,71.029-48.5,141.825-44.332,114.686,68.7,190.618,65.78S1182.14-4091.177,1244-4100.276a65.514,65.514,0,0,1,9.422-.724h.155c52.531.059,56.357,71.462,121.357,75.972,68.737,4.769,60.769-75.248,144.607-75.248s86.951,90.2,172.517,90.2,93.424-90.2,184.274-90.2c49.578,0,82.725,22.633,102.616,44.154v102.69Z" transform="translate(-59.948 4101)" fill="#fff"/>
</svg>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
  data() {
    return {
      illustration: require("../assets/img/header-illustration@2x.png"),
    };
  },
};
</script>
