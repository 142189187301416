import { createApp } from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import router from "./router";
import "bootstrap";
import "./scss/main.scss";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const app = createApp(App);

app.use(router);

app.use(VueGtag, {
    config: { 
        id: "G-ZZ9KMP1XM1", 
    },
 },router);
    
app.mount("#app");
