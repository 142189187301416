<template>
  <div id="app">
    <header class="fixed-top">
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light"
        :class="[{ change_color: scrollPosition > 50 }]"
        :style="{ background: navbarColors[$route.path] }"
      >
        <div class="container-fluid">
          <router-link to="/"
            ><img :src="logo" class="navbar-brand"
          /></router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link
                  to="Over-ons"
                  class="nav-link"
                  aria-current="page"
                  href="#"
                  >Over ons</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/Fotos" class="nav-link" href="#"
                  >Foto's</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/Contact" class="nav-link" href="#"
                  >Contact</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/Doneren"
                  class="nav-link donerenButton"
                  href="#"
                  >Doneren</router-link
                >
              </li>
              <!-- <li class="nav-item dropdown">
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <a class="dropdown-item" href="#">Something else here</a>
                </li>
              </ul>
            </li> -->
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "./components/TheFooter.vue";
export default {
  components: { TheFooter },
  name: "TheNavigation",
  data() {
    return {
      logo: require("./assets/img/logo@2x.png"),
      scrollPosition: null,
      navbarColors: {
        "/Doneren": "#FFFFFF!important",
        "/Contact": "#FFFFFF!important",
      },
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  
};
</script>
