<template>
<h2 data-aos="fade-up" data-aos-duration="1500">Reacties van ouders</h2>
  <div class="container head-reviews-container" data-aos="fade-up" data-aos-duration="1500">
    <div class="row">
      <div class="col"></div>

      <div class="col-lg-8 col-md-10 col-10">
        <Carousel :items-to-show="1">
          <Slide v-for="review in reviews" :key="review">
            <div class="carousel__item">
              <div class="container">
                <div class="row">
                  <div class="col"><p><strong>{{ review.messege }}</strong></p></div>
                </div>
                <div class="row row2">
                  <div class="col-6"><p>{{ review.name }}</p></div>
                  <div class="col-6"><p>{{ review.date }}</p></div>
                  <div class="col"></div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Basic",
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },

  data() {
    return {
      reviews: [
        //   voeg hier de reviews in voor in de carousel
        {
          messege:
            "Nogmaals super bedankt! Ik ken je wel niet, maar ik wil je echt een dikke knuffel geven voor alles wat je voor ons doet! Dit betekent echt veel voor ons!",
          name: "Lonneke, mama van Lev*",
          date: "22-01-2023",
        },
        {
          messege:
            "Super mooi wikkelsetje, dat heeft gezorgd dat wij op een mooie manier afscheid konden nemen. Dankjewel hiervoor!",
          name: "Eva en Jos",
          date: "20-12-2021",
        },
        {
          messege:
            "Dankzij het bijbehorende doekje hebben wij voor altijd een mooi aandenken aan ons sterrenkindje... Dankjewel",
          name: "Mieke en Dennis",
          date: "26-11-2021",
        },
      ],
    };
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
